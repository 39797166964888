import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import Content from "../components/Content";
import {
  deleteASupplier,
  getSuppliers,
} from "../features/supplier/supplierSlice";
import CustomModal from "../components/CustomModal";

const SupplierList = () => {
  const [open, setOpen] = useState(false);
  const [sID, setSID] = useState();
  const dispatch = useDispatch();

  const showModal = (id) => {
    setOpen(true);
    setSID(id);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Vendor Code",
      dataIndex: "vendorCode",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  useEffect(() => {
    dispatch(getSuppliers());
  }, [dispatch]);

  const supplierData = useSelector((state) => state.supplier.suppliers);
  console.log(supplierData);

  const data1 = supplierData.map((item, index) => ({
    key: index + 1,
    supplierName: item.supplierName,
    vendorCode: item.vendorCode,
    action: (
      <>
        <Link to={`/admin/supplier/${item._id}`} className="fs-3 text-success">
          <BiEdit />
        </Link>
        <button
          className="ms-3 fs-3 text-danger bg-transparent border-0"
          onClick={() => showModal(item._id)}
        >
          <AiFillDelete />
        </button>
      </>
    ),
  }));

  const handleSupplier = (id) => {
    dispatch(deleteASupplier(id)).then(() => {
      dispatch(getSuppliers());
      setOpen(false);
    });
  };

  return (
    <Content>
      <h4 className="mb-4 title">Supplier List</h4>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>

      <CustomModal
        hideModal={hideModal}
        open={open}
        performAction={() => handleSupplier(sID)}
        title="Are you sure you want to delete this Supplier?"
      />
    </Content>
  );
};

export default SupplierList;
