import React from "react";
import { Line, Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart,
  LineElement,
  PointElement, // Import PointElement
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

// Register the necessary Chart.js components
Chart.register(
  LineElement,
  PointElement, // Register PointElement
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement // Include ArcElement if you are using Doughnut charts too
);
export const OrderChart = () => {
  return (
    <Doughnut
      data={{
        labels: ["Processing", "Cancelled", "Completed"],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
            ],
            hoverOffset: 4,
          },
        ],
      }}
      options={{
        plugins: {
          datalabels: {
            display: false, // Disable data labels
          },
          tooltip: {
            enabled: true,
            backgroundColor: "black",
            titleFontSize: 13,
            titleFontColor: "#6783b8",
            titleMarginBottom: 6,
            bodyFontColor: "#9eaecf",
            bodyFontSize: 12,
            bodySpacing: 4,
            yPadding: 10,
            xPadding: 10,
            footerMarginTop: 0,
            displayColors: false,
          },
          legend: {
            display: false,
            position: "right",
            labels: {
              fontColor: "#333",
              usePointStyle: true,
            },
          },
        },
        rotation: 1,
        cutout: "50%",
        maintainAspectRatio: false,
      }}
    />
  );
};

export const SalesChart = () => {
  return (
    <Line
      data={{
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Sales Data",
            data: [65, 59, 80, 81, 56, 55, 40],
            borderColor: "rgb(75, 192, 192)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            fill: true,
            tension: 0.1,
          },
        ],
      }}
      options={{
        plugins: {
          tooltip: {
            enabled: true,
            backgroundColor: "black",
            titleFont: {
              size: 13,
              color: "#6783b8",
            },
            bodyFont: {
              size: 12,
              color: "#9eaecf",
            },
            padding: 10,
            displayColors: false,
          },
          legend: {
            position: "top",
            labels: {
              color: "#333",
            },
          },
        },
        scales: {
          x: {
            title: {
              display: false,
              text: "Month",
            },
          },
          y: {
            title: {
              display: false,
              text: "Sales",
            },
          },
        },
        maintainAspectRatio: false,
      }}
    />
  );
};
