// InvoiceModal.js
import React, { useRef } from "react";
import { Modal } from "antd";
import { Table } from "reactstrap";
import jsPDF from "jspdf";
import { toPng } from "html-to-image";

const InvoiceModal = ({ open, onClose, data }) => {
  const TableRow = ({ label, value }) => (
    <tr>
      <td className="text-nowrap">{label}</td>
      <td className="text-nowrap">{value}</td>
    </tr>
  );

  const invoiceRef = useRef(null);

  // const handleDownloadPDF = () => {
  //   const pdf = new jsPDF();

  //   // Adding content to the PDF
  //   doc.text("Invoice Details", 10, 10);
  //   doc.text(`Invoice No: ${data.invoice}`, 10, 20);
  //   doc.text(`Created On: ${data.created}`, 10, 30);
  //   doc.text(`User Name: Shubham_16`, 10, 40);
  //   doc.text(`Phone Number: 9889766754`, 10, 50);
  //   // Save the PDF
  //   doc.save(`invoice_${data.invoice}.pdf`);
  // };

  async function handleDownloadPDF() {
    let arr = [invoiceRef.current];
    let photoArr = [];
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    for (let index = 0; index < arr.length; index++) {
      const res = arr[index];
      await toPng(res, { quality: 0.5 })
        .then(function (dataUrl) {
          photoArr.push(dataUrl);
          const imgProps = pdf.getImageProperties(dataUrl);
          const imgWidth = pdfWidth;
          const imgHeight = (imgProps.height * pdfWidth) / imgProps.width;

          // Scale image to fit within PDF dimensions
          const scaleFactor = Math.min(
            pdfWidth / imgProps.width,
            pdfHeight / imgProps.height
          );
          const scaledWidth = imgProps.width * scaleFactor;
          const scaledHeight = imgProps.height * scaleFactor;

          pdf.addImage(
            dataUrl,
            "PNG",
            0,
            0,
            scaledWidth,
            scaledHeight,
            undefined,
            "FAST"
          ); // Added compression option
          if (index !== arr.length - 1) {
            pdf.addPage();
          }
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    }

    pdf.save("invoice.pdf");
  }

  let styleSheet = {
    maincontainer: {
      width: "100%",
      height: "auto",
      position: "relative",
      padding: "25px",
      // background: "linear-gradient(251.07deg, #FFFFFF 35.06%, #E8F9FA 95.96%)",
      // margin:'0 auto'
      background: "white",
    },
  };

  console.log(data, "at pdf---");

  return (
    <React.Fragment>
      {" "}
      <div style={{ width: "905px" }}>
        <div
          style={{
            height: "0px",
            overflow: "hidden",
          }}
        >
          <div
            style={styleSheet.maincontainer}
            id="print"
            className="border"
            ref={invoiceRef}
          >
            <div className="d-flex justify-content-center">
              <div className="fs-5">Tax Invoice</div>
            </div>
            <div className="d-flex justify-content-between  align-items-center">
              <div>
                <img src="/eyeLogo.png" width={100} alt="" />
              </div>
              <div className="flex-direction-column mt-4">
                <div>Address: EYE MASTER Pvt Lts,Noida ,India</div>
                <div className="mt-2">Mobile No: "9878675434"</div>
                <div className="mt-2">Email: "abc@gmail.com"</div>
                <div className="mt-2">GSTIN: "AAAAAAAAAAAAAAA"</div>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-4 w-100">
              <div className="flex-direction-column">
                <div>
                  <b>Name</b> :{data?.custname}
                </div>
                <div>
                  <b>Email</b> :{data?.email}
                </div>
                <div className="mt-2">
                  <b>Billing Address</b> : pin_code flat_number city state
                </div>
                <div className="mt-2">
                  <b>Phone</b> :
                </div>
              </div>
              <div className="flex-direction-column">
                <div>
                  <b>Invoice No</b> : {data?.invoice}
                </div>
                <div className="mt-2">
                  <b>Date</b> :{data?.created}
                </div>
              </div>
            </div>
            {/* <div className="mt-4">
              <table>
                <thead className="ps-5">
                  <tr>
                    <th style={{ width: "70%" }} className="pl-3">
                      Description
                    </th>
                    <th style={{ width: "10%" }} className="pl-3">
                      HSN/SAC Code
                    </th>
                    <th style={{ width: "10%" }} className="pl-3">
                      Qty
                    </th>
                    <th style={{ width: "10%" }} className="pl-3">
                      Rate (Rs)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pl-2 pt-3">
                      <div>
                        <b>Plan Name : </b> plan_name
                      </div>
                      <div className="mt-2">
                        <b>Plan Category :</b> category
                      </div>
                      <div className="mt-2">
                        <b>Description :</b> shortDescription
                      </div>
                    </td>
                    <td className="pl-2">
                      {hsndata.map((res) => {
                        return <>{res?.code}</>;
                      })}
                    </td>
                    <td className="pl-2">1</td>

                    <td className="pl-2">amount</td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-right pr-2 pt-2">
                      <div>
                        <b>Price :</b>
                      </div>
                      <div className="mt-3">
                        <b>SGST :</b>
                      </div>
                      <div className="mt-3">
                        <b> CGST :</b>
                      </div>
                    </td>
                    <td className="pl-2 pt-2">
                      <div className="">amount</div>
                      <div className="mt-3">sgst</div>
                      <div className="mt-3">cgst</div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-right pr-2 ">
                      <div>
                        <b>Total:</b>
                      </div>
                    </td>
                    <td className="pl-2">100</td>
                  </tr>
                </tbody>
              </table>
            </div> */}
            <div className="mt-3">
              <b className="f-14">Term</b>
              <div>1. Total payment due in 10 days</div>
              <div>2. After due date you will pay Rs.50 extra.T&C APPLY*</div>
              <div>3. Cheque Payable to PHP Radius.</div>
              <div>Company's Bank details:</div>
              <div>Bank Name:</div>
              <div>Bank Account No.:</div>
              <div>Bank IFSC code:</div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        okText={"Download PDF"}
        centered
        title="Invoice"
        open={open}
        onCancel={onClose}
        width={800}
        className="custom-modal"
        onOk={handleDownloadPDF}
      >
        {data && (
          <div>
            <div className="row">
              <div className="d-flex col-5">
                <div className="col-6 ">
                  <h6 style={{ whiteSpace: "nowrap" }}>Invoice No:</h6>
                  <div className="fs-6">{data.invoice} </div>
                </div>
                <div className="col-6">
                  <h6 style={{ whiteSpace: "nowrap" }}>Created On :</h6>
                  <div className="fs-6">{data.created} </div>
                </div>
              </div>
              <div className="col-2 d-flex align-items-center">
                <div
                  style={{
                    width: "1px",
                    height: "100%",
                    margin: "0 auto",
                    backgroundColor: "lightgray",
                  }}
                ></div>
              </div>
              <div className="d-flex col-5">
                <div className="col-6">
                  <h6 style={{ whiteSpace: "nowrap" }}>User Name:</h6>
                  <div className="fs-6">Shubham_16</div>
                </div>
                <div className="col-6">
                  <h6 style={{ whiteSpace: "nowrap" }}>Phone Number:</h6>
                  <div className="fs-6">9889766754</div>
                </div>
              </div>
            </div>
            <div className="mt-5 ">
              <h6 className="fs-5">Invoice Details</h6>
            </div>
            <div className="mt-3">
              <Table hover className="fs-6">
                <tbody>
                  <>
                    <TableRow label="Customer Name" value={data.custname} />
                    <TableRow label="Product Name" value={data.productname} />
                    <TableRow label="Status" value={data.status} />
                    <TableRow label="Email" value={data.email} />
                    <TableRow label="Amount" value={data.amount} />
                  </>
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default InvoiceModal;
