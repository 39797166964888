import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import supplierService from "./supplierService";

export const getSuppliers = createAsyncThunk(
  "supplier/get-suppliers",
  async (thunkAPI) => {
    try {
      return await supplierService.getSuppliers();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getASupplier = createAsyncThunk(
  "supplier/get-supplier",
  async (id, thunkAPI) => {
    try {
      return await supplierService.getSupplier(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createSuppliers = createAsyncThunk(
  "supplier/create-suppliers",
  async (supplierData, thunkAPI) => {
    try {
      return await supplierService.createSupplier(supplierData);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const deleteASupplier = createAsyncThunk(
  "supplier/delete-supplier",
  async (id, thunkAPI) => {
    try {
      return await supplierService.deleteSupplier(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateASupplier = createAsyncThunk(
  "supplier/update-supplier",
  async (brand, thunkAPI) => {
    try {
      return await supplierService.updateSupplier(brand);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const resetState = createAction("Reset_all");

const initialState = {
  suppliers: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};
export const supplierSlice = createSlice({
  name: "suppliers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSuppliers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSuppliers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.suppliers = action.payload;
      })
      .addCase(getSuppliers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(createSuppliers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSuppliers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.createSupplier = action.payload;
      })
      .addCase(createSuppliers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(getASupplier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getASupplier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.supplierName = action.payload.supplierName;
        state.vendorCode = action.payload.vendorCode;
        state.description = action.payload.description;
        state.images = action.payload.images;
      })
      .addCase(getASupplier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(deleteASupplier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteASupplier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.deletedProduct = action.payload;
      })
      .addCase(deleteASupplier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(updateASupplier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateASupplier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.updatedProduct = action.payload;
      })
      .addCase(updateASupplier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error;
      })
      .addCase(resetState, () => initialState);
  },
});
export default supplierSlice.reducer;
