import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Content from "../components/Content";
import CustomInput from "../components/CustomInput";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";

const Manufacturer = () => {
  const [previewImage, setPreviewImage] = useState(null);

  const formik = useFormik({
    initialValues: {
      ManufacturerName: "",
      description: "",
      images: null,
    },
    validationSchema: Yup.object({
      ManufacturerName: Yup.string().required("Manufacturer name is required"),
      description: Yup.string(),
      images: Yup.mixed().required("Manufacturer images is required"),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    formik.setFieldValue("images", file);
    // console.log(file,"inside the manfuctarer-")
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("images", null);
    setPreviewImage(null);
  };

  return (
    <Content>
      <div>
        <h4 className="mb-4 title">Add Manufacturer</h4>
        <form onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            label="Enter Manufacturer Name"
            id="ManufacturerName"
            name="ManufacturerName"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.ManufacturerName}
          />
          {formik.touched.ManufacturerName && formik.errors.ManufacturerName ? (
            <div className="error">{formik.errors.ManufacturerName}</div>
          ) : null}

          <div className="form-floating mt-3">
            <textarea
              style={{ height: "13vh" }}
              className="form-control"
              id="description"
              placeholder="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={4}
              cols={50}
            />
            <label htmlFor="description">Enter Description</label>
          </div>

          <div className="mt-3">
            <label htmlFor="images" className="form-label">
              Add Manufacturer Images
            </label>
            <div className="p-4 d-flex align-items-center justify-content-center bg-light">
              {previewImage ? (
                <div className=" position-relative">
                  <div>
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                    <span
                      className="position-absolute text-danger top-0 end-0 rounded-circle "
                      style={{ cursor: "pointer" }}
                      onClick={handleRemoveImage}
                    >
                      <RxCross2 size={22} />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <p className="text-muted mb-2">Drop files here to upload</p>
                  <label htmlFor="images" className="btn btn-primary">
                    Upload file
                    <input
                      type="file"
                      id="images"
                      name="images"
                      className="d-none"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
          {formik.touched.images && formik.errors.images ? (
            <div className="error">{formik.errors.images}</div>
          ) : null}

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary border-0 rounded-3 my-5"
              type="submit"
            >
              Add Manufacturer
            </button>
          </div>
        </form>
      </div>
    </Content>
  );
};

export default Manufacturer;
