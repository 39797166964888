import React, { useState } from "react";
import { Table } from "antd";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import Content from "../components/Content";

const ManufacturerList = () => {
  const [open, setOpen] = useState(false);
  const [pCatId, setpCatId] = useState("");
  const dispatch = useDispatch();

  const showModal = (id) => {
    setOpen(true);
    setpCatId(id);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const columns = [
    {
      title: "SNo",
      dataIndex: "key",
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) => (
        <>
          <Link to={``} className="fs-3 text-success">
            <BiEdit />
          </Link>
          <button
            className="ms-3 fs-3 text-danger bg-transparent border-0"
            onClick={() => showModal(record.key)}
          >
            <AiFillDelete />
          </button>
        </>
      ),
    },
  ];

  const dummyData = [
    {
      key: 1,
      name: "Classic Eyeglasses",
    },
    {
      key: 2,
      name: "Modern Sunglasses",
    },
    {
      key: 3,
      name: "Vintage Frames",
    },
  ];

  return (
    <Content>
      <h4 className="mb-4 title">Manufacturer List</h4>
      <div>
        <Table columns={columns} dataSource={dummyData} />
      </div>
    </Content>
  );
};

export default ManufacturerList;
