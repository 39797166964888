// import React, { useEffect, useState } from "react";
// import { Table } from "antd";
// import { BiEdit } from "react-icons/bi";
// import { AiFillDelete } from "react-icons/ai";
// import { useDispatch, useSelector } from "react-redux";
// import { deleteAProduct, getProducts } from "../features/product/productSlice";
// import { Link } from "react-router-dom";
// import { delImg } from "../features/upload/uploadSlice";
// import CustomModal from "../components/CustomModal";

// const columns = [
//   {
//     title: "SNo",
//     dataIndex: "key",
//   },
//   {
//     title: "Name",
//     dataIndex: "name",
//     sorter: (a, b) => a.name.length - b.name.length,
//   },
//   {
//     title: "Time",
//     dataIndex: "time",
//     // sorter: (a, b) => a.time.length - b.time.length,
//   },
//   {
//     title: "Date",
//     dataIndex: "date",
//     // sorter: (a, b) => a.category.length - b.category.length,
//   },

//   {
//     title: "Quantity",
//     dataIndex: "quantity",
//   },
// ];

// const Appointment = () => {
//   const [open, setOpen] = useState(false);
//   const [productId, setproductId] = useState("");
//   const dispatch = useDispatch();
//   useEffect(() => {
//     dispatch(getProducts());
//   }, []);
//   const productState = useSelector((state) => state?.product?.products);
//   const data1 = [];
//   for (let i = 0; i < productState.length; i++) {
//     data1.push({
//       key: i + 1,
//       title: productState[i].title,
//       brand: productState[i].brand,
//       category: productState[i].category,
//       color: productState[i].color,
//       quantity: productState[i].quantity,
//     });
//   }

//   return (
//     <div>
//       <h4 className="mb-4 title">Appointment</h4>
//       <div>
//         <Table columns={columns} dataSource={data1} />
//       </div>
//     </div>
//   );
// };

// export default Appointment;

import React, { useState } from "react";
import { Table } from "antd";
import { type } from "@testing-library/user-event/dist/type";
import Content from "../components/Content";

const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Doctor Name",
    dataIndex: "docname",
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: "Customer Name",
    dataIndex: "custname",
    sorter: (a, b) => a.name.length - b.name.length,
  },
  {
    title: "Time",
    dataIndex: "time",
  },
  {
    title: "Date",
    dataIndex: "date",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
];

const Appointment = () => {
  // Static data
  const data1 = [
    {
      key: 1,
      docname: "Dr Paras Wigham",
      custname: "Shubham Yadav",
      time: "10:00 AM",
      date: "2024-08-07",
      type: "Inperson",
    },
    {
      key: 2,
      docname: "Dr R.D Mukheja",
      custname: "Varun Saxena",
      time: "11:00 AM",
      date: "2024-08-08",
      type: "Online",
    },
    {
      key: 3,
      docname: "Dr Sandeep",
      custname: "Abhishek Singh",
      time: "12:00 PM",
      date: "2024-08-09",
      type: "Inperson",
    },
    {
      key: 4,
      docname: "Dr Umesh Maurya",
      custname: "Akash Sharma",
      time: "01:00 PM",
      date: "2024-08-10",
      type: "Online",
    },
    {
      key: 5,
      docname: "Dr Y.D Singh",
      custname: "Sachin Rawat",
      time: "02:00 PM",
      date: "2024-08-11",
      type: "Inperson",
    },
  ];

  return (
    <Content>
      <div>
        <h4 className="mb-4 title">Appointments</h4>
        <div>
          <Table columns={columns} dataSource={data1} />
        </div>
      </div>
    </Content>
  );
};

export default Appointment;
