import React, { useEffect, useState } from "react";
import { Table, Badge } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getMonthlyData,
  getOrders,
  getYearlyData,
} from "../../features/auth/authSlice";
import Content from "../../components/Content";
import { OrderChart, SalesChart } from "./Dashchart";
import { FcConferenceCall } from "react-icons/fc";
import { FcBullish } from "react-icons/fc";
import { FcDatabase } from "react-icons/fc";
import { FcPackage } from "react-icons/fc";

const Dashboard = () => {
  const dispatch = useDispatch();

  const monthlyDataState = useSelector((state) => state?.auth?.monthlyData);
  const yearlyDataState = useSelector((state) => state?.auth?.yearlyData);
  const orderState = useSelector((state) => state?.auth?.orders?.orders);
  console.log(orderState);

  const [dataMonthly, setDataMonthly] = useState([]);
  const [dataMonthlySales, setDataMonthlySales] = useState([]);
  const [orderData, setOrderData] = useState([]);

  const getTokenFromLocalStorage = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const config3 = {
    headers: {
      Authorization: `Bearer ${
        getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""
      }`,
      Accept: "application/json",
    },
  };

  useEffect(() => {
    dispatch(getMonthlyData(config3));
    dispatch(getYearlyData(config3));
    dispatch(getOrders(config3));
  }, []);

  useEffect(() => {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let data = [];

    let monthlyOrderCount = [];
    for (let index = 0; index < monthlyDataState?.length; index++) {
      const element = monthlyDataState[index];
      data.push({
        type: monthNames[element?._id?.month],
        income: element?.amount,
      });
      monthlyOrderCount.push({
        type: monthNames[element?._id?.month],
        income: element?.count,
      });
    }

    setDataMonthly(data);
    setDataMonthlySales(monthlyOrderCount);

    const data1 = [];

    for (let i = 0; i < orderState?.length; i++) {
      data1.push({
        key: i,
        name: orderState[i].user.firstname + " " + orderState[i].user.lastname,
        product: orderState[i].orderItems?.length,
        price: orderState[i]?.totalPrice,
        dprice: orderState[i]?.totalPriceAfterDiscount,
        staus: orderState[i]?.orderStatus,
      });
    }
    setOrderData(data1);
  }, [monthlyDataState, yearlyDataState]);

  const config = {
    data: dataMonthly,
    xField: "type",
    yField: "income",
    color: ({ type }) => {
      return "#ffd333";
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month",
      },
      sales: {
        alias: "Income",
      },
    },
  };

  const config2 = {
    data: dataMonthlySales,
    xField: "type",
    yField: "income",
    color: ({ type }) => {
      return "#ffd333";
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month",
      },
      sales: {
        alias: "Income",
      },
    },
  };

  const dashInfo = [
    {
      title: "Today Orders",
      value: "1945",
    },
    {
      title: "Today Revenue",
      value: "Rs 2000",
    },
    {
      title: "Today Customers",
      value: "845",
    },
    {
      title: "Today Visitors",
      value: "2345",
    },
  ];

  const storeStats = [
    {
      title: "Orders",
      value: "1795",
      icon: <FcBullish size={32} />,
    },
    {
      title: "Customers",
      value: "2327",
      icon: <FcConferenceCall size={32} />,
    },
    {
      title: "Products",
      value: "674",
      icon: <FcPackage size={32} />,
    },
    {
      title: "Categories",
      value: "68",
      icon: <FcDatabase size={32} />,
    },
  ];

  const columns = [
    {
      title: "Order No.",
      dataIndex: "orderno",
    },
    {
      title: "Customer",
      dataIndex: "customer",
      render: (customer, record) => {
        const color = getStatusColor(record.status);
        return (
          <div className="d-flex align-items-center">
            <div
              style={{
                width: "25px",
                height: "25px",
                fontSize: "11px",
                backgroundColor: color,
              }}
              className="d-flex justify-content-center align-items-center rounded-circle text-white"
            >
              {getInitials(customer)}
            </div>
            <div className="ms-2">{customer}</div>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        const color = getStatusColor(status);
        return (
          <Badge
            color={color}
            text={<span style={{ color: color }}>{status}</span>}
          />
        );
      },
    },
  ];

  const orderInfo = [
    {
      key: 1,
      orderno: "#9594",
      customer: "Shubham Yadav",
      date: "10:00 AM",
      amount: "2024",
      status: "Paid",
    },
    {
      key: 2,
      orderno: "#9595",
      customer: "Naomi Lawrence",
      date: "10:00 AM",
      amount: "2025",
      status: "Cancelled",
    },
    {
      key: 3,
      orderno: "#9596",
      customer: "Paras Jain",
      date: "10:00 AM",
      amount: "2026",
      status: "Paid",
    },
    {
      key: 4,
      orderno: "#9597",
      customer: "Smith",
      date: "10:00 AM",
      amount: "2026",
      status: "Due",
    },
    {
      key: 5,
      orderno: "#9598",
      customer: "Cassandra",
      date: "10:00 AM",
      amount: "2027",
      status: "Paid",
    },
  ];

  const topProducts = [
    {
      title: "Classic Eyeglasses",
      price: "$90.00",
      sell: "10 Sold",
    },
    {
      title: "Premium Eyeglasses",
      price: "$99.00",
      sell: "10 Sold",
    },
    {
      title: "Screen Eyeglasses",
      price: "$99.00",
      sell: "10 Sold",
    },
    {
      title: "Sun Eyeglasses",
      price: "$99.00",
      sell: "10 Sold",
    },
    {
      title: "Power Sunglasses",
      price: "$99.00",
      sell: "10 Sold",
    },
  ];

  const getInitials = (name) => {
    return name
      .split(" ")
      .map((word) => word[0])
      .join("")
      .toUpperCase();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Paid":
        return "green";
      case "Cancelled":
        return "red";
      case "Due":
        return "orange";
      default:
        return "gray";
    }
  };
  return (
    <Content>
      <div className="row">
        {dashInfo?.map((item) => (
          <div className="col-md-3 col-sm-6 col-12">
            <div className="card shadow-sm rounded-3 border-light">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-end">
                  <div>
                    <h6
                      style={{
                        color: "#364a63",
                        fontWeight: "600",
                        fontSize: "1rem",
                      }}
                      className=""
                    >
                      {item?.title}
                    </h6>
                    <h5
                      className=""
                      style={{
                        color: "#364a63",
                        fontWeight: "500",
                        fontSize: "2rem",
                      }}
                    >
                      {item?.value}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="row mt-5">
        <div className="col-md-6 col-sm-6 col-12">
          <div className="card shadow-sm rounded-3 border-light h-100">
            <div className="card-body">
              <h6
                style={{
                  color: "#364a63",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Sales Statics
              </h6>
              <div className="d-flex align-items-center justify-content-center mt-5">
                <SalesChart />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="card shadow-sm rounded-3 border-light h-100">
            <div className="card-body ">
              <h6
                style={{
                  color: "#364a63",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Order Statics
              </h6>
              <div className="d-flex align-items-center justify-content-center mt-5">
                <OrderChart />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 col-12">
          <div className="card shadow-sm rounded-3 border-light h-100">
            <div className="card-body">
              <h6
                style={{
                  color: "#364a63",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Store Statics
              </h6>
              <div>
                {storeStats?.map((item) => (
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="d-flex flex-column">
                      <div className="title">{item?.title}</div>
                      <div
                        style={{
                          color: "#364a63",
                          fontWeight: "500",
                          fontSize: "1.5rem",
                        }}
                      >
                        {item?.value}
                      </div>
                    </div>
                    <div>{item.icon}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-8 col-12">
          <div className="card shadow-sm rounded-3 border-light h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-end">
                <div className="w-100">
                  {/* Ensure this div takes full width */}
                  <h5
                    style={{
                      color: "#364a63",
                      fontWeight: "500",
                      fontSize: "1rem",
                    }}
                  >
                    Recent Orders
                  </h5>
                  <div className="mt-3">
                    <Table
                      columns={columns}
                      dataSource={orderInfo}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="card shadow-sm rounded-3 border-light h-100">
            <div className="card-body">
              <h6
                style={{
                  color: "#364a63",
                  fontWeight: "600",
                  fontSize: "1rem",
                }}
              >
                Top Proucts
              </h6>
              <div>
                {topProducts?.map((item) => (
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="d-flex flex-column">
                      <div
                        className="title"
                        style={{
                          color: "#364a63",
                          fontWeight: "400",
                          fontSize: "1rem",
                        }}
                      >
                        {item?.title}
                      </div>
                      <div
                        style={{
                          color: "#364a63",
                          fontWeight: "400",
                          fontSize: "1rem",
                        }}
                      >
                        {item?.price}
                      </div>
                    </div>
                    {/* right column */}
                    <div className="d-flex flex-column">
                      <div
                        className="title"
                        style={{
                          color: "#364a63",
                          fontWeight: "500",
                          fontSize: "1rem",
                          textAlign: "left",
                        }}
                      >
                        {item?.price}
                      </div>
                      <div
                        className=""
                        style={{
                          color: "#364a63",
                          fontWeight: "400",
                          fontSize: "1rem",
                          textAlign: "right",
                        }}
                      >
                        {item?.sell}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
      <div className="mt-4 ">
        <h4 className="mb-5 title">Recent Orders</h4>
        <div className="card shadow-sm rounded-3 border-light">
          <div className="card-body">
            <Table columns={columns} dataSource={orderData} />
          </div>
        </div>
      </div> */}
    </Content>
  );
};

export default Dashboard;
