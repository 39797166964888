import React, { useState } from "react";
import { Table } from "antd";
import Content from "../../components/Content";
import InvoiceModal from "./InvoiceModal";

const InvoicePreview = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const handleRowClick = (info) => {
    setModalData(info);
    setOpenModal(true);
  };

  const columns = [
    {
      title: "Invoice No.",
      dataIndex: "invoice",
      render: (text, info) => (
        <a onClick={() => handleRowClick(info)}>{text}</a>
      ),
    },
    {
      title: "Created On",
      dataIndex: "created",
    },
    {
      title: "Product Name",
      dataIndex: "productname",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Customer Name",
      dataIndex: "custname",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
  ];
  const data1 = [
    {
      invoice: 188,
      created: "09/08/2024",
      productname: "Sun Glasses",
      amount: "$100",
      custname: "Shubham",
      email: "email@gmail.com",
      status: "Paid",
    },
    {
      invoice: 122,
      created: "09/08/2024",
      productname: "Sun Glasses",
      amount: "$100",
      custname: "Shubham",
      email: "email@gmail.com",
      status: "Due",
    },
    {
      invoice: 133,
      created: "09/08/2024",
      productname: "Sun Glasses",
      amount: "$100",
      custname: "Shubham",
      email: "email@gmail.com",
      status: "Pending",
    },
    {
      invoice: 122,
      created: "09/08/2024",
      productname: "Sun Glasses",
      amount: "$100",
      custname: "Shubham",
      email: "email@gmail.com",
      status: "Paid",
    },
    {
      invoice: 1455,
      created: "09/08/2024",
      productname: "Sun Glasses",
      amount: "$100",
      custname: "Shubham",
      email: "email@gmail.com",
      status: "Pending",
    },
  ];

  return (
    <Content>
      <div>
        <h4 className="mb-4 title">Invoices</h4>
        <div>
          <Table columns={columns} dataSource={data1} />
          <InvoiceModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            data={modalData}
          />
        </div>
      </div>
    </Content>
  );
};

export default InvoicePreview;
