import { React, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useLocation, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getBrands } from "../features/brand/brandSlice";
import { getCategories } from "../features/pcategory/pcategorySlice";
import { getColors } from "../features/color/colorSlice";
import { Select } from "antd";
import Dropzone from "react-dropzone";
import { delImg, uploadImg } from "../features/upload/uploadSlice";
import {
  createProducts,
  getAProduct,
  resetState,
  updateAProduct,
} from "../features/product/productSlice";
import Content from "../components/Content";
import CustomInput from "./../components/CustomInput";

let schema = yup.object().shape({
  title: yup.string().required("Title is Required"),
  description: yup.string().required("Description is Required"),
  price: yup.number().required("Price is Required"),
  brand: yup.string().required("Brand is Required"),
  category: yup.string().required("Category is Required"),
  // tags: yup.string().required("Tag is Required"),
  color: yup
    .array()
    .min(1, "Pick at least one color")
    .required("Color is Required"),
  quantity: yup.number().required("Quantity is Required"),
});

const Addproduct = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const getProductId = location.pathname.split("/")[3];
  const navigate = useNavigate();
  const [color, setColor] = useState([]);
  const [images, setImages] = useState([]);
  // console.log(color);
  useEffect(() => {
    dispatch(getBrands());
    dispatch(getCategories());
    dispatch(getColors());
  }, []);

  const brandState = useSelector((state) => state.brand.brands);
  const catState = useSelector((state) => state.pCategory.pCategories);
  const colorState = useSelector((state) => state.color.colors);
  const imgState = useSelector((state) => state?.upload?.images);
  const newProduct = useSelector((state) => state.product);
  const {
    isSuccess,
    isError,
    isLoading,
    createdProduct,
    updatedProduct,
    productName,
    productDesc,
    productPrice,
    productBrand,
    productCategory,
    productTag,
    productColors,
    productQuantity,
    productImages,
  } = newProduct;
  console.log(newProduct, "aaaaaaaaaaaaa");

  useEffect(() => {
    if (getProductId !== undefined) {
      dispatch(getAProduct(getProductId));
    } else {
      dispatch(resetState());
    }
  }, [getProductId]);

  useEffect(() => {
    if (isSuccess && createdProduct) {
      toast.success("Product Added Successfullly!");
      navigate("/admin/list-product");
    }
    if (isSuccess && updatedProduct) {
      toast.success("Product Updated Successfullly!");
      navigate("/admin/list-product");
    }
    if (isError) {
      toast.error("Something Went Wrong!");
    }
  }, [isSuccess, isError, isLoading]);
  const coloropt = [];
  colorState.forEach((i) => {
    coloropt.push({
      label: (
        <div className="col-3">
          <ul
            className="colors ps-0"
            style={{
              width: "20px",
              height: "20px",
              marginBottom: "10px",
              backgroundColor: i.title,
              borderRadius: "50%", // Added inline style for rounded shape
              listStyle: "none", // Hide bullet points
              border: "2px solid transparent",
            }}
          ></ul>
        </div>
      ),
      value: i._id,
    });
  });

  const productcolor = [];
  productColors?.forEach((i) => {
    productcolor.push({
      label: (
        <div className="col-3">
          <ul
            className="colors ps-0"
            style={{
              width: "20px",
              height: "20px",
              marginBottom: "10px",
              backgroundColor: i.title,
              borderRadius: "50%", // Added inline style for rounded shape
              listStyle: "none", // Hide bullet points
              border: "2px solid transparent",
            }}
          ></ul>
        </div>
      ),
      value: i._id,
    });
  });

  let img = [];
  imgState?.forEach((i) => {
    img.push({
      public_id: i.public_id,
      url: i.url,
    });
  });

  let [imgshow, setImgShow] = useState([]);

  useEffect(() => {
    if (productImages?.length !== 0) {
      productImages?.forEach((i) => {
        imgshow.push({
          public_id: i.public_id,
          url: i.url,
        });
      });
    }
  }, [productImages]);

  useEffect(() => {
    formik.values.color = color ? color : " ";
    formik.values.images = img;
  }, [color, img]);

  const formik = useFormik({
    initialValues: {
      title: productName || "",
      description: productDesc || "",
      price: productPrice || "",
      brand: productBrand || "",
      category: productCategory || "",
      // tags: productTag || "",
      color: productColors || "",
      quantity: productQuantity || "",
      images: productImages || "",
    },
    // enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      console.log(values);
      if (getProductId !== undefined) {
        const data = { id: getProductId, productData: values };
        dispatch(updateAProduct(data));
      } else {
        dispatch(createProducts(values));
        formik.resetForm();
        setColor(null);
        setTimeout(() => {
          dispatch(resetState());
        }, 3000);
      }
    },
  });

  const handleColors = (e) => {
    setColor(e);
    console.log(color);
  };

  useEffect(() => {
    formik.setValues({
      title: productName || "",
      description: productDesc || "",
      price: productPrice || "",
      brand: productBrand || "",
      category: productCategory || "",
      // tags: productTag || "",
      color: productColors || [],
      quantity: productQuantity || "",
      images: productImages || [],
    });
  }, [
    productName,
    productDesc,
    productPrice,
    productBrand,
    productCategory,
    productTag,
    productColors,
    productQuantity,
    productImages,
  ]);

  const handleDeleteImage = (public_id) => {
    console.log(public_id);
    dispatch(delImg(public_id)).then(() => {
      const updatedImages = imgshow.filter(
        (img) => img.public_id !== public_id
      );
      imgshow.length = 0;
      setImgShow(updatedImages);
      formik.setFieldValue("images", [...updatedImages]);
    });
  };

  return (
    <Content>
      <h4 className="mb-4 title">
        {getProductId !== undefined ? "Edit" : "Add"} Product
      </h4>
      <div>
        <form
          onSubmit={formik.handleSubmit}
          className="d-flex gap-2 flex-column"
        >
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="text"
                label="Enter product title"
                name="title"
                onChng={formik.handleChange("title")}
                onBlr={formik.handleBlur("title")}
                val={formik.values.title}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.title && formik.errors.title}
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="number"
                label="Enter product price"
                name="price"
                onChng={formik.handleChange("price")}
                onBlr={formik.handleBlur("price")}
                val={formik.values.price}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.price && formik.errors.price}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12">
              <select
                name="brand"
                onChange={formik.handleChange("brand")}
                onBlur={formik.handleBlur("brand")}
                value={formik.values.brand}
                className="form-control py-3 mb-3"
                id=""
              >
                <option value="">Select Brand</option>
                {brandState.map((i, j) => {
                  return (
                    <option key={j} value={i.title}>
                      {i.title}
                    </option>
                  );
                })}
              </select>
              <div className="error mt-2 fs-6">
                {formik.touched.brand && formik.errors.brand}
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <select
                name="category"
                onChange={formik.handleChange("category")}
                onBlur={formik.handleBlur("category")}
                value={formik.values.category}
                className="form-control py-3 mb-3"
                id=""
              >
                <option value="">Select Category</option>
                {catState.map((i, j) => {
                  return (
                    <option key={j} value={i.title}>
                      {i.title}
                    </option>
                  );
                })}
              </select>
              <div className="error mt-2 fs-6">
                {formik.touched.category && formik.errors.category}
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-md-6 col-sm-6 col-12 mt-2 d-flex align-items-center">
              <Select
                mode="multiple"
                allowClear
                className="w-100"
                placeholder="Select colors"
                value={color}
                // defaultValue={productcolor || color}
                onChange={(i) => handleColors(i)}
                options={coloropt}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.color && formik.errors.color}
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <CustomInput
                type="number"
                label="Enter product quantity"
                name="quantity"
                onChng={formik.handleChange("quantity")}
                onBlr={formik.handleBlur("quantity")}
                val={formik.values.quantity}
              />
              <div className="error mt-2 fs-6">
                {formik.touched.quantity && formik.errors.quantity}
              </div>
            </div>
          </div>
          <div className="mt-3">
            <ReactQuill
              style={{ height: "10rem " }}
              theme="snow"
              name="description"
              onChange={formik.handleChange("description")}
              value={formik.values.description}
            />
          </div>
          <div className="error mt-2 fs-6">
            {formik.touched.description && formik.errors.description}
          </div>

          <div className="bg-white border-1 p-5 text-center border mt-5">
            <Dropzone
              onDrop={(acceptedFiles) => dispatch(uploadImg(acceptedFiles))}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p>Click to Select Files</p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className="showimages d-flex flex-wrap gap-3">
            {console.log(imgshow, "RENDERING-----")}
            {imgshow?.map((i, j) => {
              return (
                <div className=" position-relative" key={j}>
                  <button
                    type="button"
                    onClick={() => handleDeleteImage(i.public_id)}
                    className="btn-close position-absolute"
                    style={{ top: "10px", right: "10px" }}
                  ></button>
                  <img src={i.url} alt="" width={200} height={200} />
                </div>
              );
            })}
            {console.log(imgState, "RENDERING222222-----")}
            {imgState?.map((i, j) => {
              return (
                <div className=" position-relative" key={j}>
                  <button
                    type="button"
                    onClick={() => handleDeleteImage(i.public_id)}
                    className="btn-close position-absolute"
                    style={{ top: "10px", right: "10px" }}
                  ></button>
                  <img src={i.url} alt="" width={200} height={200} />
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary  border-0 rounded-3 my-3"
              type="submit"
            >
              {getProductId !== undefined ? "Edit" : "Add"} Product
            </button>
          </div>
        </form>
      </div>
    </Content>
  );
};

export default Addproduct;
