import { React, useEffect } from "react";
import CustomInput from "../components/CustomInput";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  createColor,
  getAColor,
  resetState,
  updateAColor,
} from "../features/color/colorSlice";
import Content from "../components/Content";
let schema = yup.object().shape({
  colorName: yup.string().required("Color is Required"),
});
const Addcolor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const id = location.pathname.split("/")[3];
  const newColor = useSelector((state) => state.color);
  const { id } = useParams();
  const {
    isSuccess,
    isError,
    isLoading,
    createdColor,
    updatedColor,
    colorName,
    colorData,
  } = newColor;
  console.log(newColor, "getcoloriddsssddddd");
  // console.log(useSelector((state) => state.color), 'useSelector((state) => state.color)');

  // useEffect(() => {
  //   if (id !== undefined) {
  //     dispatch(getAColor(id));
  //   } else {
  //     dispatch(resetState());
  //   }
  // }, [id]);

  // useEffect(() => {
  //   if (isSuccess && createdColor) {
  //     toast.success("Color Added Successfullly!");
  //   }
  //   if (isSuccess && updatedColor) {
  //     toast.success("Color Updated Successfullly!");
  //     // navigate("/admin/list-color");
  //   }
  //   if (isError) {
  //     toast.error("Something Went Wrong!");
  //   }
  // }, [isSuccess, isError, isLoading, createdColor]);

  useEffect(() => {
    if (id) {
      dispatch(getAColor(id));
    } else {
      dispatch(resetState());
    }
    return () => dispatch(resetState());
  }, [id, dispatch]);

  useEffect(() => {
    if (isSuccess && createdColor) {
      toast.success("Color added successfully!");
      navigate("/admin/list-color");
    }
    if (isSuccess && updatedColor) {
      toast.success("Color updated successfully!");
      navigate("/admin/list-color");
    }
    if (isError) {
      toast.error("Something went wrong!");
    }
  }, [isSuccess, isError, createdColor, updatedColor, navigate]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: colorData?.title || "",
      colorName: colorData?.colorName || "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (id !== undefined) {
        
        const data = { id: id, colorData: values };
        console.log(data, 'dataaaaaaaaa');
        // return
        dispatch(updateAColor(data));
        dispatch(resetState());
      } else {
        dispatch(createColor(values));
        formik.resetForm();
        setTimeout(() => {
          dispatch(resetState());
        }, 300);
      }
    },
  });
  return (
    <Content>
      <div>
        <h4 className="mb-4 title">
          {id !== undefined ? "Edit" : "Add"} Color
        </h4>
        <div>
          <form action="" onSubmit={formik.handleSubmit}>
            <CustomInput
              type="text"
              label="Enter Color Name"
              onChng={formik.handleChange("title")}
              onBlr={formik.handleBlur("title")}
              val={formik.values.title}
              id="color"
            />

            <CustomInput
              type="color"
              label="Enter Product Color"
              onChng={formik.handleChange("colorName")}
              onBlr={formik.handleBlur("colorName")}
              val={formik.values.colorName}
              id="color"
            />
            <div className="error">
              {formik.touched.colorName && formik.errors.colorName}
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary border-0 rounded-3 my-5"
                type="submit"
              >
                {id !== undefined ? "Edit" : "Add"} Color
              </button>
            </div>
          </form>
        </div>
      </div>
    </Content>
  );
};

export default Addcolor;
