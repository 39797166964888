import React from "react";

const Content = ({ page, children }) => {
  return (
    <div className="container-fluid my-5">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              {!page ? children : null}
              {page === "component" ? (
                <div className="components-preview">{children}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
