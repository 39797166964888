import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Content from "../components/Content";
import CustomInput from "../components/CustomInput";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  createSuppliers,
  resetState,
} from "../features/supplier/supplierSlice";
import { uploadImg } from "../features/upload/uploadSlice";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

// const test = useSelection((test) => test)

let schema = yup.object().shape({
  supplierName: yup.string().required("Supplier Name is Required"),
  vendorCode: yup.string().required("Vendor Code is Required"),
  description: yup.string().required("Description is Required"),
  // images: yup.string().required("Brand is Required"),
});

const Supplier = () => {
  const [previewImage, setPreviewImage] = useState(null);
  const [img, setImg] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newSupplier = useSelector((state) => state.supplier);

  console.log(newSupplier,"newsupplier----");

  const { isSuccess, isError, isLoading } = newSupplier;

  const formik = useFormik({
    initialValues: {
      supplierName: "",
      vendorCode: "",
      description: "",
      images: [],
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        if (values.images) {
          const imageUrl = await handleImageUpload();
          const formData = {
            ...values,
            images: {
              public_id: imageUrl.payload[0].public_id,
              url: imageUrl.payload[0].url,
            },
          };
          dispatch(createSuppliers(formData));
          formik.resetForm();
          setTimeout(() => {
            dispatch(resetState());
          }, 3000);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  // useEffect(() => {
  //   if (isSuccess === true) {
  //     toast.success("Supplier Added Successfullly!");
  //     navigate("/admin/SupplierList");
  //   }
  //   if (isError) {
  //     toast.error("Something Went Wrong!");
  //   }
  // }, [isSuccess, isError, isLoading]);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    setImg(file);
    formik.setFieldValue("images", file);
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleImageUpload = async () => {
    try {
      const imageUrl = await dispatch(uploadImg([img]));
      return imageUrl;
    } catch (error) {
      console.error("Image upload failed:", error);
      throw error;
    }
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("images", null);
    setPreviewImage(null);
  };

  return (
    <Content>
      <div>
        <h4 className="mb-4 title">Add Supplier</h4>
        <form onSubmit={formik.handleSubmit}>
          <CustomInput
            type="text"
            label="Enter Supplier Name"
            id="supplierName"
            name="supplierName"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.supplierName}
          />
          {formik.touched.supplierName && formik.errors.supplierName ? (
            <div className="error">{formik.errors.supplierName}</div>
          ) : null}

          <CustomInput
            type="text"
            label="Enter Vendor code"
            id="vendorCode"
            name="vendorCode"
            onChng={formik.handleChange}
            onBlr={formik.handleBlur}
            val={formik.values.vendorCode}
          />

          <div className="form-floating mt-3">
            <textarea
              style={{ height: "13vh" }}
              className="form-control"
              id="description"
              placeholder="Description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={4}
              cols={50}
            />
            <label htmlFor="description">Enter Description</label>
          </div>

          <div className="mt-3">
            <label htmlFor="images" className="form-label">
              Add Supplier Icon
            </label>
            <div className="p-4 d-flex align-items-center justify-content-center bg-light">
              {previewImage ? (
                <div className=" position-relative">
                  <div>
                    <img
                      src={previewImage}
                      alt="Preview"
                      style={{ maxWidth: "200px", maxHeight: "200px" }}
                    />
                    <span
                      className="position-absolute text-danger top-0 end-0 rounded-circle "
                      style={{ cursor: "pointer" }}
                      onClick={handleRemoveImage}
                    >
                      <RxCross2 size={22} />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <p className="text-muted mb-2">Drop files here to upload</p>
                  <label htmlFor="images" className="btn btn-primary">
                    Upload file
                    <input
                      type="file"
                      id="images"
                      name="images"
                      className="d-none"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                  </label>
                </div>
              )}
            </div>
          </div>
          {formik.touched.images && formik.errors.images ? (
            <div className="error">{formik.errors.images}</div>
          ) : null}

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary border-0 rounded-3 my-5"
              type="submit"
            >
              Add Supplier
            </button>
          </div>
        </form>
      </div>
    </Content>
  );
};

export default Supplier;
