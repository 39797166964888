import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../features/auth/authSlice";
import { Input, Spinner, Card, CardBody } from "reactstrap";

let schema = yup.object().shape({
  email: yup
    .string()
    .email("Email should be valid")
    .required("Email is Required"),
  password: yup.string().required("Password is Required"),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });

  const authState = useSelector((state) => state);
  console.log(authState, "authstate on Click-----");
  const { user, isError, isSuccess, isLoading, message } = authState.auth;

  console.log(message, "onclick-----");

  useEffect(() => {
    if (isSuccess) {
      window.location.href = "/admin";
    } else {
      navigate("");
    }
  }, [user, isError, isSuccess, isLoading]);

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="p-1">
        <div className="mb-4 text-center">
          <img
            src="/eyeLogo.png"
            className="img-fluid"
            alt="Logo"
            style={{ maxWidth: "130px" }}
          />
        </div>
        <div className="col-12 d-flex align-items-center justify-content-center">
          <Card style={{ maxWidth: "800px", width: "100%", padding: "30px" }}>
            <h1 className="text-center">Log in to your account</h1>
            <h6 className="text-center mt-4">
              Welcome Back! Please enter your details
            </h6>
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-4">
                <label className="form-label-group">Email Address</label>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  onChange={formik.handleChange("email")}
                  onBlur={formik.handleBlur("email")}
                  value={formik.values.email}
                  className="form-control"
                />
                <div className="error mt-2 fs-6">
                  {formik.touched.email && formik.errors.email}
                </div>
              </div>

              <div className="mt-4">
                <label className="form-label-group">Password</label>
                <Input
                  type="password"
                  id="pass"
                  name="password"
                  onChange={formik.handleChange("password")}
                  onBlur={formik.handleBlur("password")}
                  value={formik.values.password}
                  className="form-control"
                />
                <div className="error mt-2 fs-6">
                  {formik.touched.password && formik.errors.password}
                </div>
              </div>

              <div className="error text-left fs-6">
                {message?.message === "Rejected" ? "Invalid Credentials" : ""}
              </div>

              <button
                className="btn btn-primary w-100 fw-bold fs-5 mt-4"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? <Spinner size="sm" color="light" /> : "Sign in"}
              </button>
            </form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Login;
